import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/house-on-sorority-row-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, The House On Sorority Row"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 08</h1>
                    <h2>The House On Sorority Row</h2>
                    <h3>May 29, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Mark Rosman // <b>Starring:</b> Kate McNeil &amp; Eileen Davidson</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/08-The-House-on-Sorority-Row-e24rus8" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast, episode 8, The House On Sorority Row"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-house-on-sorority-row">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave close out their School's Out series, taking a look at the 1982 slasher picture, The House on Sorority Row, from director Mark Rosman, a hidden gem of the genre that remarkably passes the Bechdel Test. It's a movie about a prank gone wrong, secret medical experiments, and a girl who owns a 1970's style fuck van. The House on Sorority Row features soap opera stars, Kate McNeil and Eileen Davidson, as well as Lois Kelso and we enjoyed this movie a whole lot.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=IKMrteGf6q8" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=IKMrteGf6q8</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/vamp">Next Episode</Link></li>
                        <li><Link to="/episodes/final-exam">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)